import logo from './logo.svg';
import MjIcon from './assets/image/icon.png'
import DucklinIcon from './assets/image/logo_icon_no_bg.png'
import './App.css';
import { 
  Button, Navbar, Nav, NavDropdown, FormControl
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="true">
      <Navbar.Brand href="#home">
        <img src={DucklinIcon} className="icon" alt="logo" /> Ducklin HK</Navbar.Brand>
      {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#link">Link</Nav.Link>
          <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Form inline>
          <FormControl type="text" placeholder="Search" className="mr-sm-2" />
          <Button variant="outline-success">Search</Button>
        </Form>
      </Navbar.Collapse> */}
    </Navbar>

      <header className="App-header">
        <img src={MjIcon} className="Mj-Icon" alt="logo" />
        <h1 style={{marginTop: 10}}>
          Mahjong Tutor
        </h1>
        <p>
          Public beta now
        </p>
        <a
          className="App-link"
          href="https://testflight.apple.com/join/9sO0EqrJ"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download from TestFlight
        </a>
      </header>
    </div>
  );
}

export default App;
